"use client";

import { Button, Icons, SectionTitle } from "shared/ui";
import styles from "app/(static)/home/sections/media-section/media-section.module.scss";
import cx from "classnames";
import Link from "next/link";
import Image from "next/image";
import images from "app/(static)/home/sections/media-section/images";
import { Video } from "shared/ui/video";
import { useEffect, useRef, useState } from "react";
import { yandexMetrika } from "shared/lib";
export const MediaSection = () => {
  const excursionVideoRef = useRef<HTMLVideoElement>(null);
  const [showExcursionVideo, setShowExcursionVideo] = useState(false);
  const onClickPlayButton = () => {
    const elem = excursionVideoRef.current;
    if (elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
        elem.play();
      }
    }
  };
  const fullScreenChange = () => {
    if (document.fullscreenElement) {
      // We’re going fullscreen
    } else {
      excursionVideoRef.current?.pause();
      setShowExcursionVideo(false);
    }
  };
  useEffect(() => {
    document.addEventListener("fullscreenchange", fullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", fullScreenChange);
    };
  }, []);
  const onClickVkCard = () => {
    yandexMetrika.reachGoal("klik_vk");
  };
  return <div className={styles.media_section} data-sentry-component="MediaSection" data-sentry-source-file="media-section.tsx">
      <div className={styles.container}>
        <div className={styles.heading_content}>
          <h2 className={styles.heading_content_title}>Мы в media</h2>
          <div className={styles.heading_content_sub_title}>
            Poizon Shop & Unicorn – открытый, публичный проект. У нас нет секретов, рассказываем обо всех процессах!
          </div>
          <div className={styles.heading_content_description}>
            Освящаем свою деятельность и оставляем след в медиа. Можете почитать статьи про нас, посмотреть блогеров,
            которые сотрудничают с нами. Присоединяйтесь в наши аккаунты в соцсетях с живыми подписчиками. Читайте
            комментарии покупателей, которые уже заказывали что-то через нас.
          </div>
        </div>

        <div className={styles.content_cards}>
          <div className={styles.chapter}>
            <div className={styles.row}>
              <Link href="https://t.me/+ry13-CCJRZszOWYy" target="_blank" rel="nofollow" className={cx(styles.card, styles.telegram)} data-sentry-element="Link" data-sentry-source-file="media-section.tsx">
                <Image src={images.mockupIphone} alt="" className={styles.image} data-sentry-element="Image" data-sentry-source-file="media-section.tsx" />
                <div className={styles.content}>
                  <div className={styles.heading}>
                    <div className={styles.title}>
                      Telegram-канал <div className={styles.accent_txt}>Poizon Shop</div>
                    </div>
                    <div className={styles.sub_title}>91 500 подписчиков</div>
                  </div>
                  <div className={styles.description}>
                    Самое крупное и открытое сообщество Poizon в ТГ, там регулярно публикуются подборки интересных
                    товаров, релизы новинок, акции, скидки и розыгрыши.
                  </div>
                  <p className={styles.description}>
                    В канале под каждым постом открыты комментарии и вы можете пообщаться с людьми, которые совершали у
                    нас покупки и убедиться в качестве.
                  </p>
                  <Button type="accent" size="large" data-sentry-element="Button" data-sentry-source-file="media-section.tsx">
                    <Icons.TelegramDarkLogo data-sentry-element="unknown" data-sentry-source-file="media-section.tsx" />
                    Перейти в канал
                  </Button>
                </div>
              </Link>
            </div>
            <div className={cx(styles.chapter, styles.col)}>
              <Link href="https://vc.ru/promo/526701-poizon-shop" target="_blank" rel="nofollow" className={cx(styles.card, styles.vc_ru)} data-sentry-element="Link" data-sentry-source-file="media-section.tsx">
                <Icons.ExtraLink className={styles.extra_link_icon} data-sentry-element="unknown" data-sentry-source-file="media-section.tsx" />

                <div className={styles.logo_box}>
                  <Image src={images.logoVcRu} alt="" className={styles.logo} data-sentry-element="Image" data-sentry-source-file="media-section.tsx" />
                </div>
                <div className={styles.contnet}>
                  <div className={styles.title}>Издание VC.RU</div>
                  <div className={styles.description}>
                    Про нас писали в издании vc.ru. Рассказывали про нашу команду, компанию и чем мы занимаемся.
                  </div>
                </div>
              </Link>
              <Link href="https://www.youtube.com/@Tujh91" target="_blank" rel="nofollow" className={cx(styles.card, styles.youtube)} data-sentry-element="Link" data-sentry-source-file="media-section.tsx">
                <Icons.ExtraLink className={styles.extra_link_icon} data-sentry-element="unknown" data-sentry-source-file="media-section.tsx" />
                <div className={styles.heading}>
                  <Image src={images.egor} alt="" className={styles.avatar} data-sentry-element="Image" data-sentry-source-file="media-section.tsx" />
                </div>
                <div className={styles.content}>
                  <div className={styles.title}>
                    Егор Пупынин <Icons.YoutubePlay className={styles.youtube_icon} data-sentry-element="unknown" data-sentry-source-file="media-section.tsx" />
                  </div>
                  <p className={styles.description}>
                    Наш амбассадор – самый крупный блогер в сфере волейбольной тематики рекомендует нас на своем канале
                  </p>
                </div>
              </Link>
            </div>
          </div>
          <div className={styles.chapter}>
            <div className={cx(styles.card, styles.excursion)} id="excursion-card">
              <div className={styles.title}>
                Экскурсия <br /> по Poizon Shop
              </div>
              <div className={styles.play_button_box}>
                <Icons.Play className={styles.play_button} onClick={() => {
                setShowExcursionVideo(true);
                setTimeout(onClickPlayButton);
              }} data-sentry-element="unknown" data-sentry-source-file="media-section.tsx" />
              </div>
              <Image src={images.ilya} alt="" className={styles.image} data-sentry-element="Image" data-sentry-source-file="media-section.tsx" />

              {showExcursionVideo && <video ref={excursionVideoRef} controls preload="none" className={cx(styles.excursion_video, showExcursionVideo && styles.show)} id="excursion-video">
                  <source src="https://cdn.unicorngo.ru/videos/excursion.mp4" type="video/mp4" />
                </video>}
            </div>
            <Link href="https://vk.com/unicorngoru" target="_blank" rel="nofollow" className={cx(styles.card, styles.vk)} onClick={onClickVkCard} data-sentry-element="Link" data-sentry-source-file="media-section.tsx">
              <Icons.ExtraLink className={styles.extra_link_icon} data-sentry-element="unknown" data-sentry-source-file="media-section.tsx" />
              <Icons.VkLogo className={styles.logo} data-sentry-element="unknown" data-sentry-source-file="media-section.tsx" />
              <div className={styles.heading}>
                <div className={styles.title}>Официальное верифицированное сообщество в ВК</div>
                <div className={styles.sub_title}>45 911 подписчиков</div>
              </div>
              <div className={styles.description}>
                Наша компания проходила проверку и официальный мониторинг, чтобы получить галочку.
              </div>
            </Link>
            <div className={cx(styles.card, styles.video)}>
              <Video autoPlay loop muted playsInline preload="none" className={styles.video} image={images.videoPreview} data-sentry-element="Video" data-sentry-source-file="media-section.tsx">
                <source src="https://cdn.unicorngo.ru/videos/dancing-poizon.mp4" type="video/mp4" />
              </Video>
            </div>
          </div>
        </div>
      </div>
    </div>;
};