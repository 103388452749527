"use client";

import { Button, Icons, InputWithLabel, Pill } from "shared/ui";
import styles from "app/(static)/home/sections/cover-section/ui/search-box/search-box.module.scss";
import { useRouter } from "next/navigation";
import Link from "next/link";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { catalogTree } from "entity/catalog-brand";
import { catalogApi } from "shared/api";
import { analytics } from "entity/analytics";
import { KeyName, LocalStorage, Modals, typograf, useClickOutside, useKeyDown } from "shared/lib";
import * as searchIcons from "features/page-search/assets";
import { PageSearch } from "features/page-search";
export const SearchBox = () => {
  const {
    title,
    href,
    ...subcategoriesRecord
  } = catalogTree.sneakers;
  const subcategories = Object.values(subcategoriesRecord);
  const [searchValue, setSearchValue] = useState<string>("");
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [lastSearch, setLastSearch] = useState<string[]>([]);
  const router = useRouter();
  const [selectedItem, setSelectedItem] = useState<number>(-1);
  const [listVisible, setListVisible] = useState(false);
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("lastSearch")!);
    if (items) {
      setLastSearch(items);
    }
  }, []);
  const suggest = catalogApi.catalog.useCatalogCatalogSuggester(searchValue.trim(), 10);
  const [savedSuggest, setSavedSuggest] = useState<string[]>([]);
  useEffect(() => {
    if (suggest.data && suggest.data.suggestions.length !== 0) {
      setSavedSuggest(suggest.data.suggestions);
    }
  }, [suggest.data]);
  const popular = catalogApi.catalog.useCatalogCatalogSuggester("", 10);
  const [savedPopular, setSavedPopular] = useState<string[]>([]);
  useEffect(() => {
    if (popular.data) setSavedPopular(popular.data.suggestions);
  }, [popular.data]);
  const handleSearch = async (value: string, save?: boolean) => {
    if (value !== "") {
      if (save) {
        if (!lastSearch.includes(value)) {
          LocalStorage.setItem("lastSearch", JSON.stringify([value, ...lastSearch]));
        }
      }
      router.push("/search-page?sort=search-relevance&search=" + value);
    }
  };
  const removeItemFromHistory = (item: string) => {
    analytics.send("home-page.lastSearch.deleteItem", {
      item: item
    });
    if (lastSearch.length === 1) {
      setLastSearch([]);
    } else {
      setLastSearch(prevState => prevState.filter(state => state !== item));
    }
  };
  useKeyDown(KeyName.Enter, () => {
    analytics.send("home-page.searchInput.EnterButton", {
      searchValue: searchValue
    });
    if (selectedItem === -1) {
      handleSearch(searchValue, true);
    } else {
      handleSearch(savedSuggest[selectedItem], true);
    }
  });
  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const regex = /^[a-zA-Zа-яА-Я0-9 ]+$/;
    if (value && !regex.test(value)) {
      return;
    }
    if (searchValue === "" && value.endsWith(" ")) {
      return;
    }
    setSavedSuggest([]);
    setSelectedItem(-1);
    setSearchValue(value);
  };
  const handleKeyDown = (e: {
    key: string;
  }) => {
    const key = e.key;
    if (key === "ArrowDown") {
      setSelectedItem(prevState => prevState === savedSuggest?.length - 1 ? prevState : prevState + 1);
    } else if (key === "ArrowUp") {
      setSelectedItem(prevState => prevState === -1 ? prevState : prevState - 1);
    }
  };
  useClickOutside(wrapperRef, () => setListVisible(false));
  return <div className={styles.search_box} data-sentry-component="SearchBox" data-sentry-source-file="search-box.tsx">
      <div className={styles.title}>Здесь найдется любой товар</div>
      <div className={styles.inputMobile} onClick={() => Modals.open(PageSearch, {})}>
        <InputWithLabel placeholder="Введите название бренда или модели" prefix={<Icons.Search />} size="large" name="search" noOutline data-sentry-element="InputWithLabel" data-sentry-source-file="search-box.tsx" />
      </div>
      <div className={styles.wrapper} ref={wrapperRef}>
        <div className={styles.search_input_group}>
          <InputWithLabel className={styles.input} placeholder="Введите название бренда или модели" prefix={<Icons.Search />} size="large" name="search" noOutline autoComplete="off" onChange={handleChangeInput} value={searchValue} onClickContainer={() => setListVisible(true)} onKeyDown={handleKeyDown} data-sentry-element="InputWithLabel" data-sentry-source-file="search-box.tsx" />
          <Button size="large" type="accent" className={styles.button} htmlType="submit" data-sentry-element="Button" data-sentry-source-file="search-box.tsx">
            Найти
          </Button>
        </div>
        {listVisible && <div className={styles.searchListDesktop}>
            {searchValue === "" ? <>
                {lastSearch.length !== 0 && <div className={styles.searchHistoryDesktop}>
                    <h2>Вы искали</h2>
                    {lastSearch.slice(0, 4).map((item: string, index: number) => <button key={index} onClick={() => {
              analytics.send("home-page.lastSearch.itemClicked", {
                lastSearch: item
              });
              handleSearch(item, false);
            }}>
                        <div className={styles.main}>
                          <searchIcons.searchHistoryIcon />
                          {item}
                        </div>
                        <button onClick={e => {
                e.stopPropagation();
                removeItemFromHistory(item);
              }}>
                          <searchIcons.closeButtonIcon />
                        </button>
                      </button>)}
                  </div>}
                <div className={styles.searchHistoryDesktop}>
                  <h2>Часто ищут</h2>
                  {savedPopular.slice(0, 8).map((item: string, index: number) => <button key={index} onClick={() => {
              analytics.send("home-page.popular.clicked", {
                searchValue: searchValue,
                popularItem: item
              });
              handleSearch(item, true);
            }}>
                      <div className={styles.main}>
                        <searchIcons.searchItemDesktopIcon />
                        {item}
                      </div>
                    </button>)}
                </div>
              </> : (savedSuggest.length === 0 ? [searchValue] : savedSuggest)?.map((item, index) => <button onClick={() => {
          analytics.send("home-page.clickedSuggest.fromPC", {
            searchValue: searchValue,
            suggestValue: item
          });
          handleSearch(item, true);
        }} data-selected={selectedItem === index} key={index}>
                  <div>
                    <span>{searchValue}</span>
                    {item.split(searchValue.toLowerCase())[item.split(searchValue.toLowerCase()).length - 1]}
                  </div>
                </button>)}
          </div>}
      </div>

      <div className={styles.brand_box}>
        <div className={styles.label}>Популярные бренды</div>
        <div className={styles.brand_list}>
          {subcategories.map(brand => <Link key={brand.href} href={brand.href} className={styles.brand}>
              <Pill>{brand.title}</Pill>
            </Link>)}
        </div>
      </div>
    </div>;
};