"use client";

import { Button, Icons } from "shared/ui";
import { yandexMetrika } from "shared/lib";
import * as amplitude from "@amplitude/analytics-browser";
import React, { CSSProperties } from "react";
export interface IReviewsButton {
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
}
export const ReviewsButton = (props: IReviewsButton) => {
  return <Button className={props.className} style={props.style} size="large" type="accent" onClick={() => {
    yandexMetrika.reachGoal("telegram-reviews-open");
    amplitude.track("Telegram Reviews Open");
    window.open("https://t.me/+qBUcttDHvGY3MjAy", "_blank");
  }} data-sentry-element="Button" data-sentry-component="ReviewsButton" data-sentry-source-file="reviews-button.tsx">
      <Icons.TelegramDarkLogo data-sentry-element="unknown" data-sentry-source-file="reviews-button.tsx" /> Telegram-канал с отзывами
    </Button>;
};