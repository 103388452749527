import { ButtonHTMLAttributes, forwardRef } from "react";
import cx from "classnames";
import styles from "./pill.module.scss";
export type IPill = Omit<ButtonHTMLAttributes<HTMLButtonElement>, "type"> & {
  type?: "white" | "dark" | "gray";
  children?: React.ReactNode;
  htmlType?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  className?: string;
};
export const Pill = forwardRef<HTMLButtonElement, IPill>(({
  type = "white",
  htmlType,
  className,
  ...props
}, ref) => {
  return <button className={cx(styles.pill, styles[type], className)} type={htmlType} ref={ref} {...props}>
      {props.children}
    </button>;
});