import egor from "app/(static)/home/sections/media-section/images/egor.webp"
import ilya from "app/(static)/home/sections/media-section/images/ilya.webp"
import logoVcRu from "app/(static)/home/sections/media-section/images/logo-vc-ru.webp"
import mockupIphone from "app/(static)/home/sections/media-section/images/mockup-iphone.webp"
import videoPreview from "app/(static)/home/sections/media-section/images/video-preview.webp"

export default {
  egor,
  ilya,
  logoVcRu,
  mockupIphone,
  videoPreview,
}
