"use client"

import { useMemo } from "react"
import useSWR, { SWRConfiguration } from "swr"
import qs from "querystring"
import { useLocalStorage } from "shared/lib"

export const useTgInviteLink = () => {
  const defaults = {
    inviteLinkUrl: "https://t.me/+oTPWkR_0Zk4yODcy",
    botStartUrl: "https://t.me/unicorn_go_bot?start=seo",
  }

  const utmStr = useLocalStorage("utm")
  const utm = useMemo(() => (utmStr ? JSON.parse(utmStr) : undefined), [utmStr])

  const inviteLinks = useUtmInviteLink(utm)

  return {
    botStartUrl: utm ? inviteLinks.data?.botStartUrl || defaults.botStartUrl : defaults.botStartUrl,
    inviteLinkUrl: utm ? inviteLinks.data?.inviteLinkUrl || defaults.inviteLinkUrl : defaults.inviteLinkUrl,
  }
}

const useUtmInviteLink = (
  utm: Record<string, string>,
  config?: SWRConfiguration<{
    inviteLinkUrl: string
    botStartUrl: string
  }>,
) => {
  return useSWR(() => getKey(utm), request, config)
}

const getKey = (utm: Record<string, string>) => {
  if (!utm || !Object.values(utm).length) return null

  return { url: "/api/utm-invite-link" + "?" + qs.stringify(utm) }
}

const request = async (data: { url: string }) => {
  const response = await fetch(data.url)

  if (!response.ok) throw new Error(`Api Error: ${response.status}`)

  return (await response.json()) as { inviteLinkUrl: string; botStartUrl: string }
}
