import { tripleRaf } from "./schedulers"
import { IS_MOBILE, IS_SAFARI } from "shared/lib/environment/user-agent"
import IS_TOUCH_SUPPORTED from "shared/lib/environment/touch-support"

export const IS_STICKY_INPUT_BUGGED = IS_SAFARI && IS_MOBILE && IS_TOUCH_SUPPORTED

export default function fixSafariStickyInput(input: HTMLElement) {
  input.style.opacity = "0"

  // setTimeout(() => (input.style.opacity = "1"), 1000)
  tripleRaf().then(() => (input.style.opacity = "1"))
}
