"use client";

import styles from "./sale-banner.module.scss";
import { usePathname, useRouter } from "next/navigation";
import { analytics } from "entity/analytics";
import { useEffect } from "react";
interface ISaleBanner {
  button?: boolean;
}
export const SaleBanner = ({
  button
}: ISaleBanner) => {
  const router = useRouter();
  const pathname = usePathname();
  const bannerPath = pathname === "/" ? "home" : pathname === "/my/wishlist" ? "favorites" : pathname.startsWith("/order") ? "order" : undefined;
  useEffect(() => {
    analytics.send(`11/11.banner.view-${bannerPath}`);
  }, []);
  return <>
      {!button && <div className={styles.saleBannerDesktop} onClick={() => {
      router.push("/sale");
      analytics.send(`11/11.banner.click-${bannerPath}`);
    }}>
          <div className={styles.wrapper}>
            <div className={styles.content}>
              <div className={styles.title}>
                <div className={styles.bold}>Распродажа</div>
                <div className={styles.medium}>началась</div>
              </div>
              <div className={styles.sale}>
                <div>11.11</div>
                <div className={styles.blur}>11.11</div>
              </div>
              <div className={styles.sizeSales}>
                <span className={styles.sales}>скидки</span>
                до
                <span className={styles.percent}>90%</span>
              </div>
            </div>
            <div className={styles.backgroundCircleBlur} />
          </div>
        </div>}
      <div className={styles.saleBannerMobile} data-button={button} onClick={() => router.push("/sale")}>
        <div className={styles.content}>
          <div className={styles.title}>
            Распродажа
            <br />
            началась
          </div>
          <div className={styles.sizeSales}>
            скидки до&nbsp;<span className={styles.percent}>90%</span>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <div className={styles.sale}>
            <div>11.11</div>
            <div className={styles.blur}>11.11</div>
          </div>
          <button className={styles.moreButton}>
            Подробнее
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none" data-sentry-element="svg" data-sentry-source-file="sale-banner.tsx">
              <path d="M8.25 5.97058L13.75 11.4706L8.25 16.9706" stroke="#4DA7A9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="sale-banner.tsx" />
            </svg>
          </button>
        </div>
        <div className={styles.backgroundCircleBlur} />
      </div>
    </>;
};