"use client";

import React, { DetailedHTMLProps, VideoHTMLAttributes, useEffect, useRef, useState } from "react";
import { Icons } from "..";
import styles from "./video.module.scss";
import cx from "classnames";
import Image, { StaticImageData } from "next/image";
type DefaultProps = DetailedHTMLProps<VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>;
export interface IVideo extends DefaultProps {
  children: React.ReactNode;
  disablePause?: boolean;
  showPlayButton?: boolean;
  playButtonClassName?: string;
  imageClassName?: string;
  image?: StaticImageData;
}
export const Video = ({
  children,
  image,
  disablePause,
  showPlayButton,
  playButtonClassName,
  imageClassName,
  ...props
}: IVideo) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isPlayed, setIsPlayed] = useState<boolean>(false);
  useEffect(() => {
    let options = {
      rootMargin: "0px",
      threshold: [0.25, 0.75]
    };
    let handlePlay = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      entries.forEach(entry => {
        if (videoRef.current && entry.isIntersecting && !isPlaying) {
          if (props.autoPlay) {
            setIsPlaying(true);
            videoRef.current.play();
            if (!isPlayed) setIsPlayed(true);
          }
        } else {
          if (disablePause) return;
          setIsPlaying(false);
          videoRef.current?.pause();
        }
      });
    };
    let observer = new IntersectionObserver(handlePlay, options);
    if (videoRef.current) observer.observe(videoRef.current);
  }, []);
  const onClickPlayButton = () => {
    setIsPlaying(true);
    videoRef.current?.play();
    if (!isPlayed) setIsPlayed(true);
  };
  return <div className={cx(styles.wrapper, isPlayed && styles.played, isPlaying && styles.playing, !props.autoPlay && styles.video_not_autoplay)} data-sentry-component="Video" data-sentry-source-file="video.tsx">
      <video {...props} ref={videoRef}>
        {children}
      </video>
      {showPlayButton && (!props.autoPlay || !isPlaying) && <button className={cx(styles.play_button, playButtonClassName)} onClick={onClickPlayButton}>
          <Icons.Play className={styles.play_icon} />
        </button>}
      {image && !isPlayed && <Image src={image.src} alt="video preview" placeholder="blur" width={image.width} height={image.height} blurDataURL={image.blurDataURL} className={styles.image} />}
    </div>;
};