"use client";

import cx from "classnames";
import React, { forwardRef, InputHTMLAttributes, useImperativeHandle, useRef, useState } from "react";
import styles from "./input-with-label.module.scss";
import fixSafariStickyInput, { IS_STICKY_INPUT_BUGGED } from "shared/lib/fix-safari-sticky-input";
export interface IInputWithLabel extends Omit<InputHTMLAttributes<HTMLInputElement>, "size" | "prefix"> {
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  prefixStyling?: boolean;
  suffixStyling?: boolean;
  noInlineSuffix?: boolean;
  noOutline?: boolean;
  isAccent?: boolean;
  fixSafariSticky?: boolean;
  onClickContainer?: () => void;
  size?: "small" | "medium" | "large";
}
export const InputWithLabel = forwardRef<HTMLInputElement, IInputWithLabel>((props: IInputWithLabel, ref) => {
  const innerRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(ref, () => innerRef.current!);
  const {
    value,
    size,
    className,
    style,
    id,
    prefix,
    suffix,
    prefixStyling,
    suffixStyling,
    noOutline,
    isAccent,
    fixSafariSticky,
    onClickContainer,
    ...restProps
  } = props;
  const [focus, setFocus] = useState(false);
  const canInlineSuffix = !props.noInlineSuffix && props.readOnly && props.suffix && typeof props.suffix === "string" && !props.suffixStyling;
  const onClick = () => {
    innerRef.current?.focus();
    onClickContainer && onClickContainer();
  };
  const onFocus: typeof props.onFocus = e => {
    if (fixSafariSticky && IS_STICKY_INPUT_BUGGED) {
      fixSafariStickyInput(e.currentTarget);
    }
    setFocus(true);
    props.onFocus?.(e);
  };
  const onBlur: typeof props.onBlur = e => {
    setFocus(false);
    props.onBlur?.(e);
  };
  return <div className={cx(styles.input, size === "small" && styles.small, size === "medium" && styles.medium, size === "large" && styles.large, prefix && styles.with_prefix, suffix && styles.with_suffix, !prefixStyling && prefix && styles.no_prefix_styling, !suffixStyling && suffix && styles.no_suffix_styling, focus && styles.focus, props.readOnly && styles.readonly, noOutline && styles.no_outline, isAccent && styles.accent, className)} onClick={onClick} style={style} id={id}>
      {prefix && <div className={cx(styles.prefix, !prefixStyling && styles.no_styling)}>{prefix}</div>}
      <input type="text" value={canInlineSuffix ? `${value || ""} ${suffix}` : value} {...restProps} onFocus={onFocus} onBlur={onBlur} ref={innerRef} />
      {suffix && !canInlineSuffix && <div className={cx(styles.suffix, !suffixStyling && styles.no_styling)}>{suffix}</div>}
    </div>;
});