"use client";

import { useState } from "react";
import styles from "app/(static)/home/sections/shop-section/ui/info-cards-sub-section/video-card/video-card.module.scss";
import Image from "next/image";
import images from "app/(static)/home/sections/shop-section/ui/info-cards-sub-section/images";
import cx from "classnames";
import { Icons } from "shared/ui";
export const VideoCard = () => {
  const [isVideoPlayed, setIsVideoPlaying] = useState<boolean>(false);
  const playVideo = () => {
    if (!isVideoPlayed) {
      setIsVideoPlaying(true);
    }
  };
  return <div className={cx(styles.video_card, styles.card)} onClick={playVideo} data-sentry-component="VideoCard" data-sentry-source-file="video-card.tsx">
      {!isVideoPlayed && <>
          <Image src={images.videoPreview} alt="экскурсия по poizon shop" className={styles.image} fill blurDataURL={images.videoPreview.blurDataURL} placeholder="blur" />
          <Icons.YoutubePlay className={styles.play_button} />

          <div className={styles.content}>
            <h3 className={styles.title}>
              Экскурсия по <span className={styles.accent_txt}>Poizon shop</span>
            </h3>
            <div className={styles.desc}>Рассказываем, как у нас все устроено</div>
          </div>
        </>}
      {isVideoPlayed && <iframe width="560" height="315" src={`https://www.youtube-nocookie.com/embed/PFeoshzj1W4?controls=0&autoplay=${Number(isVideoPlayed)}`} title="YouTube video player" frameBorder="0" className={cx(styles.video, isVideoPlayed && styles.show)} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>}
    </div>;
};