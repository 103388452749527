import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/catalog-popular-section/catalog-popular-section.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/categories-models-section/categories-models-section.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/cover-section/cover-section.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/guarantee-section/guarantee-section.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/guarantee-section/ui/info-cards-sub-section/info-cards-sub-section.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/guarantee-section/ui/info-video-sub-section/info-video-sub-section.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/in-stock-section/in-stock-section.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/main-categories-section/main-categories-section.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/reviews-section/reviews-section.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/reviews-section/ui/reviews-channel-sub-section/reviews-channel-sub-section.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/reviews-section/ui/reviews-sub-section/reviews-sub-section.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/shop-section/shop-section.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/shop-section/ui/address-sub-section/address-sub-section.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/shop-section/ui/catalog-in-market-sub-section/catalog-in-market-sub-section.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/shop-section/ui/heading-sub-section/heading-sub-section.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/shop-section/ui/info-cards-sub-section/info-cards-sub-section.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/split-section/split-section.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/home-page.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/categories-models-section/images/card-1.webp");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/categories-models-section/images/card-2.webp");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/categories-models-section/images/card-3.webp");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/categories-models-section/images/card-4.webp");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/categories-models-section/images/card-5.webp");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/cover-section/images/bg-image.webp");
;
import(/* webpackMode: "eager", webpackExports: ["SearchBox"] */ "/app/src/app/(static)/home/sections/cover-section/ui/search-box/search-box.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/guarantee-section/ui/info-cards-sub-section/images/image-1.webp");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/guarantee-section/ui/info-cards-sub-section/images/image-2.webp");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/guarantee-section/ui/info-video-sub-section/images/video-preview.webp");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/main-categories-section/images/accessories.webp");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/main-categories-section/images/clothes.webp");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/main-categories-section/images/sneakers.webp");
;
import(/* webpackMode: "eager", webpackExports: ["MediaSection"] */ "/app/src/app/(static)/home/sections/media-section/media-section.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/reviews-section/ui/reviews-channel-sub-section/images/image.webp");
;
import(/* webpackMode: "eager", webpackExports: ["ReviewsButton"] */ "/app/src/app/(static)/home/sections/reviews-section/ui/reviews-channel-sub-section/reviews-button.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/reviews-section/ui/reviews-sub-section/images/chat-01.webp");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/reviews-section/ui/reviews-sub-section/images/chat-02.webp");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/reviews-section/ui/reviews-sub-section/images/chat-03.webp");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/reviews-section/ui/reviews-sub-section/images/chat-04.webp");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/reviews-section/ui/reviews-sub-section/images/chat-05.webp");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/reviews-section/ui/reviews-sub-section/images/chat-06.webp");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/reviews-section/ui/reviews-sub-section/images/chat-07.webp");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/reviews-section/ui/reviews-sub-section/images/chat-08.webp");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/reviews-section/ui/reviews-sub-section/images/chat-09.webp");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/shop-section/ui/info-cards-sub-section/images/mockup-iphone.webp");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/shop-section/ui/info-cards-sub-section/images/video-preview.webp");
;
import(/* webpackMode: "eager", webpackExports: ["VideoCard"] */ "/app/src/app/(static)/home/sections/shop-section/ui/info-cards-sub-section/video-card/video-card.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/(static)/home/sections/split-section/images/image.webp");
;
import(/* webpackMode: "eager" */ "/app/src/features/page-footer/page-footer.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["CounterDynamic"] */ "/app/src/features/page-header/favorites-button/counter-dynamic.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/page-header/notification-line/notification-line.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/page-header/page-header.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/features/page-header/favorites-button/favorites-button.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/features/page-header/menu-button/menu-button.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/page-header/poizon-shop.svg");
;
import(/* webpackMode: "eager" */ "/app/src/features/page-header/profile-button/profile-button.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/page-header/search-button/search-button.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/page-header/side-menu/side-menu.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/features/page-header/side-menu/ui/side-menu-group/side-menu-group.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/features/page-header/side-menu/ui/side-menu-item-with-children/side-menu-item-with-children.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/page-header/side-menu/ui/side-menu-item/side-menu-item.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/features/page-header/unicorn.svg");
;
import(/* webpackMode: "eager", webpackExports: ["ProductCard"] */ "/app/src/features/product-card/product-card.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/sale-banner/sale-banner.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/shared/api/back/lib/logout.ts");
;
import(/* webpackMode: "eager" */ "/app/src/shared/api/back/lib/use-authed.ts");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/button/button.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/adjustments-horizontal.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/arrow-2-left.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/arrow-2-right.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/arrow-back.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/arrow-right.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/box.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/brand-telegram.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/bxs-map.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/check.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/checkbox.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/chevron-down.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/chevron-left.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/chevron-right.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/clock-hour-3.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/copy.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/currency-yuan.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/discount-2.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/external-link.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/fire.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/gps.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/guarantee.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/high-voltage.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/hugging-face.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/info-circle.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/instagram-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/logout.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/long-arrow-right.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/map-pin.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/menu.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/message-chatbot.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/metro-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/money-mouth-face.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/money-with-wings.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/package.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/phone.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/play.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/refresh.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/rocket.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/ruler-2.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/search.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/send.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/settings.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/shield-chevron.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/shopping-cart.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/smiling-face-with-hearts.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/snail.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/status-icon/check.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/status-icon/flag.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/status-icon/package.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/status-icon/truck-delivery.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/telegram-dark-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/telegram-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/thumbs-up.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/truck-2.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/truck-delivery.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/truck.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/user.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/vk-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/x.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/yandex-navigator.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/youtube-play.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/section-title/logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/section-title/section-title.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/container/container.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["TelegramLink"] */ "/app/src/shared/ui/telegram-link/telegram-link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Video"] */ "/app/src/shared/ui/video/video.tsx");
