const page = (title: string, href: string, hideMenu?: boolean) =>
  hideMenu ? { title, href, hideMenu } : { title, href }

export const catalogTree = {
  sneakers: {
    ...page("Кроссовки", "/sneakers"),

    airjordan: {
      ...page("Air Jordan", "/sneakers/airjordan"),
      white: page("White", "/sneakers/airjordan/white"),
      black: page("Black", "/sneakers/airjordan/black"),
      low: page("Low", "/sneakers/airjordan/low"),
      mid: page("Mid", "/sneakers/airjordan/mid"),
      high: page("High", "/sneakers/airjordan/high"),
      travisScott: page("Travis Scott", "/sneakers/airjordan/travis-scott"),
      airjordan1: page("Jordan 1", "/sneakers/airjordan/airjordan1", true),
      airjordan4: page("Jordan 4", "/sneakers/airjordan/airjordan4", true),
      airjordan4Retro: page("Jordan 4 Retro", "/sneakers/airjordan/airjordan4-retro", true),
      airjordan4Black: page("Jordan 4 Black", "/sneakers/airjordan/airjordan4-black", true),
      airjordanLowSpades: page("Jordan Spades", "/sneakers/airjordan/low-spades", true),
      airjordan2: page("Jordan 2", "/sneakers/airjordan/air-jordan-2", true),
      airjordan3: page("Jordan 3", "/sneakers/airjordan/air-jordan-3", true),
      airjordan5: page("Jordan 5", "/sneakers/airjordan/air-jordan-5", true),
      airjordan6: page("Jordan 6", "/sneakers/airjordan/air-jordan-6", true),
      airjordan7: page("Jordan 7", "/sneakers/airjordan/air-jordan-7", true),
      airjordan8: page("Jordan 8", "/sneakers/airjordan/air-jordan-8", true),
      airjordan9: page("Jordan 9", "/sneakers/airjordan/air-jordan-9", true),
      airjordan10: page("Jordan 10", "/sneakers/airjordan/air-jordan-10", true),
      airjordan11: page("Jordan 11", "/sneakers/airjordan/air-jordan-11", true),
      airjordan12: page("Jordan 12", "/sneakers/airjordan/air-jordan-12", true),
      airjordan13: page("Jordan 13", "/sneakers/airjordan/air-jordan-13", true),
      airjordan14: page("Jordan 14", "/sneakers/airjordan/air-jordan-14", true),
      airjordan15: page("Jordan 15", "/sneakers/airjordan/air-jordan-15", true),
      airjordan16: page("Jordan 16", "/sneakers/airjordan/air-jordan-16", true),
      airjordan17: page("Jordan 17", "/sneakers/airjordan/air-jordan-17", true),
      airjordan18: page("Jordan 18", "/sneakers/airjordan/air-jordan-18", true),
      airjordan19: page("Jordan 19", "/sneakers/airjordan/air-jordan-19", true),
      airjordan20: page("Jordan 20", "/sneakers/airjordan/air-jordan-20", true),
      airjordan21: page("Jordan 21", "/sneakers/airjordan/air-jordan-21", true),
      airjordan22: page("Jordan 22", "/sneakers/airjordan/air-jordan-22", true),
      airjordan23: page("Jordan 23", "/sneakers/airjordan/air-jordan-23", true),
      "low-spades": page("Spades", "/sneakers/airjordan/low-spades", true),
    },

    nike: {
      ...page("Nike", "/sneakers/nike"),

      airmax270: page("Air Max 270", "/sneakers/nike/airmax270"),
      airmax97: page("Air Max 97", "/sneakers/nike/airmax-97"),
      dunklow: page("Dunk Low", "/sneakers/nike/dunklow"),
      "airforce-white": page("Air Force", "/sneakers/nike/airforce"),
      dunkMid: page("Dunk Mid", "/sneakers/nike/dunk-mid"),
      dunkHigh: page("Dunk High", "/sneakers/nike/dunk-high"),
      court: page("Court", "/sneakers/nike/court"),
      blazer: page("Blazer", "/sneakers/nike/blazer-new"),
      zoom: page("Zoom", "/sneakers/nike/zoom-new"),
      black: page("Black", "/sneakers/nike/black", true),
      white: page("White", "/sneakers/nike/white", true),
      high: page("High", "/sneakers/nike/high", true),
      airmax: page("Air Max", "/sneakers/nike/airmax", true),
      socialStatus: page("Social Status", "/sneakers/nike/social-status", true),
      airmax270Black: page("Air Max 270 Black", "/sneakers/nike/airmax270-black", true),
      dunkSocialStatus: page("Dunk Social Status", "/sneakers/nike/dunk-social-status", true),
      airforce1LowBlack: page("Air Force Low Black", "/sneakers/nike/airforce1-low-black", true),
      airforce1LowWhite: page("Air Force Low White", "/sneakers/nike/airforce1-low-white", true),
      airforce1HighWhite: page("Air Force High White", "/sneakers/nike/airforce1-high-white", true),
      airforce1HighUtility: page("Air Force High Utility", "/sneakers/nike/airforce1-high-utility", true),
      airforceWhite: page("Air Force white", "/sneakers/nike/airforce-white", true),
      airforce1Black: page("Air Force black", "/sneakers/nike/airforce1-black", true),
      airforceAirforce1: page("Air Force 1", "/sneakers/nike/airforce-airforce1", true),
      airforce1High: page("Air Force 1 High", "/sneakers/nike/airforce1-high", true),
      airforce1Low: page("Air Force 1 Low", "/sneakers/nike/airforce1-low", true),
      airmax90: page("Air Max 90", "/sneakers/nike/airmax90", true),
      airmax90Black: page("Air Max 90 Black", "/sneakers/nike/airmax90-black", true),
      lebron8: page("Lebron 8", "/sneakers/nike/lebron-8", true),
      lebron9: page("Lebron 9", "/sneakers/nike/lebron-9", true),
      lebron18: page("Lebron 18", "/sneakers/nike/lebron-18", true),
      airPresto: page("Air Presto", "/sneakers/nike/air-presto", true),
      monarch: page("Monarch", "/sneakers/nike/monarch", true),
      // monarch2: page("Monarch 2", "/sneakers/nike/monarch-2", true),
      monarch4: page("Monarch 4", "/sneakers/nike/monarch-4", true),
      alphaflyNext: page("Alphafly Next", "/sneakers/nike/alphafly-next", true),
      zoomGt: page("Zoom GT", "/sneakers/nike/zoom-gt", true),
      zoomGt2: page("Zoom GT 2", "/sneakers/nike/zoom-gt2", true),
      pegasus34: page("Pegasus 34", "/sneakers/nike/pegasus-34", true),
      pegasus35: page("Pegasus 35", "/sneakers/nike/pegasus-35", true),
      pegasus36: page("Pegasus 36", "/sneakers/nike/pegasus-36", true),
      pegasus37: page("Pegasus 37", "/sneakers/nike/pegasus-37", true),
      pegasus38: page("Pegasus 38", "/sneakers/nike/pegasus-38", true),
      pegasus39: page("Pegasus 39", "/sneakers/nike/pegasus-39", true),
      pegasus40: page("Pegasus 40", "/sneakers/nike/pegasus-40", true),
      pegasusTurbo: page("Pegasus Turbo", "/sneakers/nike/pegasus-turbo", true),
      airmax98: page("Air Max 98", "/sneakers/nike/airmax-98", true),
      sbChron: page("SB Chron", "/sneakers/nike/sb-chron", true),
      sbChronSLR: page("SB Chron SLR", "/sneakers/nike/sb-chron-slr", true),
      sbChron2: page("SB Chron 2", "/sneakers/nike/sb-chron-2", true),
      kyrie8Infinity: page("Kyrie 8 Infinity", "/sneakers/nike/kyrie-8-infinity", true),
      freak: page("Freak", "/sneakers/nike/freak", true),
      freak2: page("Freak 2", "/sneakers/nike/freak-2", true),
      freak3: page("Freak 3", "/sneakers/nike/freak-3", true),
      freak4: page("Freak 4", "/sneakers/nike/freak-4", true),
      freak5: page("Freak 5", "/sneakers/nike/freak-5", true),
      "air-monarch": page("Air Monarch", "/sneakers/nike/air-monarch", true),
      "air-plus": page("Air Max Plus", "/sneakers/nike/air-plus", true),
      "air-react": page("Air Max React", "/sneakers/nike/air-react", true),
      "air-trainer": page("Air Trainer", "/sneakers/nike/air-trainer", true),
      "air-max-95": page("Air Max 95", "/sneakers/nike/air-max-95", true),
      "air-asg": page("ASG", "/sneakers/nike/air-asg", true),
      fly: page("Zoom Fly", "/sneakers/nike/fly", true),
      nocta: page("Nocta", "/sneakers/nike/nocta", true),
      pegasus: page("Pegasus", "/sneakers/nike/pegasus", true),
      "tech-fleece": page("Tech Fleece", "/sneakers/nike/tech-fleece", true),
      tiempo: page("Tiempo", "/sneakers/nike/tiempo", true),
      phantom: page("Phantom", "/sneakers/nike/phantom", true),
      cortez: page("Cortez", "/sneakers/nike/cortez", true),
      lebron: page("Lebron", "/sneakers/nike/lebron", true),
      trail: page("Trail", "/sneakers/nike/trail", true),
      tiffany: page("Tiffany", "/sneakers/nike/tiffany", true),
    },

    newbalance: {
      ...page("New Balance", "/sneakers/newbalance-new"),

      "1906r": page("1906R", "/sneakers/newbalance/1906r"),
      "2002r": page("2002R", "/sneakers/newbalance/2002r"),
      "574": page("574", "/sneakers/newbalance/574"),
      "550": page("550", "/sneakers/newbalance/550"),
      "9060": page("9060", "/sneakers/newbalance/9060"),
      "990": page("990", "/sneakers/newbalance/990"),
      "530": page("530", "/sneakers/newbalance/530"),
      last327: page("327", "/sneakers/newbalance/327"),
      last725: page("725", "/sneakers/newbalance/725"),
      last408: page("408", "/sneakers/newbalance/408"),
      last420: page("420", "/sneakers/newbalance/420"),
      last520: page("520", "/sneakers/newbalance/520"),
      last5740: page("5740", "/sneakers/newbalance/5740"),
      lastCourt: page("Court", "/sneakers/newbalance/Court"),
      lastCruz: page("Cruz", "/sneakers/newbalance/Cruz"),
      lastCypherRun: page("Cypher Run", "/sneakers/newbalance/cypher-run"),
      "574-gray": page("574 Gray", "/sneakers/newbalance/574-gray", true),
      "574-blue": page("574 Blue", "/sneakers/newbalance/574-blue", true),
      "last-650": page("650", "/sneakers/newbalance/650", true),
      "xc-72": page("XC-72", "/sneakers/newbalance/xc-72", true),
      "last-237": page("237", "/sneakers/newbalance/237", true),
      "last-997": page("997", "/sneakers/newbalance/997", true),
      "last-608": page("608", "/sneakers/newbalance/608", true),
    },

    adidasoriginals: {
      ...page("Adidas Originals", "/sneakers/adidasoriginals"),

      campus: page("Campus", "/sneakers/adidasoriginals/campus"),
      forumlow: page("Forum Low", "/sneakers/adidasoriginals/forumlow"),
      forummid: page("Forum Mid", "/sneakers/adidasoriginals/forummid"),
      teamcourt: page("Team Court", "/sneakers/adidasoriginals/teamcourt"),
      superstar: page("Superstar", "/sneakers/adidasoriginals/superstar"),
      niteball: page("Niteball", "/sneakers/adidasoriginals/niteball"),
      yeezy: page("Yeezy", "/sneakers/adidasoriginals/yeezy", true),
      "500": page("500", "/sneakers/adidasoriginals/500", true),
      "450": page("450", "/sneakers/adidasoriginals/450", true),
      "yeezy-boost": page("Yeezy Boost", "/sneakers/adidasoriginals/yeezy-boost", true),
      "yeezy-350": page("Yeezy 350", "/sneakers/adidasoriginals/yeezy-350", true),
      "yeezy-450": page("Yeezy 450", "/sneakers/adidasoriginals/yeezy-450", true),
      "yeezy-500": page("Yeezy 500", "/sneakers/adidasoriginals/yeezy-500", true),
      black: page("Black", "/sneakers/adidasoriginals/black", true),
      white: page("White", "/sneakers/adidasoriginals/white", true),
      high: page("High", "/sneakers/adidasoriginals/high", true),
      "samba-og": page("Samba OG", "/sneakers/adidasoriginals/samba-og", true),
      bunny: page("Bad Bunny", "/sneakers/adidasoriginals/bunny", true),
      copa: page("Copa", "/sneakers/adidasoriginals/copa", true),
      eqt: page("EQT", "/sneakers/adidasoriginals/eqt", true),
      gazelle: page("Gazelle", "/sneakers/adidasoriginals/gazelle", true),
      micropacer: page("Micropacer", "/sneakers/adidasoriginals/micropacer", true),
      response: page("Response", "/sneakers/adidasoriginals/response", true),
      simpsons: page("Simpsons", "/sneakers/adidasoriginals/simpsons", true),
      special: page("Special", "/sneakers/adidasoriginals/special", true),
      streetball: page("Streetball", "/sneakers/adidasoriginals/streetball", true),
      "swift-run": page("Swift Run", "/sneakers/adidasoriginals/swift-run", true),
      "swift-run-x": page("Swift Run X", "/sneakers/adidasoriginals/swift-run-x", true),
      balenciaga: page("Balenciaga x Adidas", "/sneakers/adidasoriginals/balenciaga", true),
      jogger: page("Jogger", "/sneakers/adidasoriginals/jogger", true),
      hamburg: page("Hamburg", "/sneakers/adidasoriginals/hamburg", true),
      nmd: page("NMD", "/sneakers/adidasoriginals/nmd", true),
      ozweego: page("Ozweego", "/sneakers/adidasoriginals/ozweego", true),
      "pharrell-williams": page("Pharrell Williams x Adidas", "/sneakers/adidasoriginals/pharrell-williams", true),
      "raf-simons": page("Raf Simons", "/sneakers/adidasoriginals/raf-simons", true),
      "stan-smith": page("Stan Smith", "/sneakers/adidasoriginals/stan-smith", true),
      "zx-700": page("ZX-700", "/sneakers/adidasoriginals/zx-700", true),
    },

    asics: {
      ...page("Asics", "/sneakers/asics-new"),

      gelkahana: page("Gel Kahana", "/sneakers/asics/gelkahana"),
      gelrocket: page("Gel Rocket", "/sneakers/asics/gel-rocket"),
      gelventure: page("Gel Venture", "/sneakers/asics/gel-venture"),
      gellyte: page("Gel Lyte", "/sneakers/asics/gel-lyte"),
      gelkayano: page("Gel Kayano", "/sneakers/asics/gel-kayano"),
      nova: page("Nova", "/sneakers/asics/nova"),
      metarise: page("Metarise", "/sneakers/asics/metarise"),
      gel: page("Gel", "/sneakers/asics/gel", true),
      novaSurge2: page("Nova Surge 2", "/sneakers/asics/nova-surge2", true),
      "court-mz": page("Court MZ", "/sneakers/asics/court-mz", true),
      "sky-elite-ff-mt2": page("Sky Elite FF MT 2", "/sneakers/asics/sky-elite-ff-mt2", true),
      awl: page("AWL", "/sneakers/asics/awl", true),
    },

    puma: {
      ...page("Puma", "/sneakers/puma-new"),

      black: page("Black", "/sneakers/puma/black", true),
      white: page("White", "/sneakers/puma/white", true),
      high: page("High", "/sneakers/puma/high", true),
      leather: page("Leather", "/sneakers/puma/leather", true),
    },

    reebok: {
      ...page("Reebok", "/sneakers/reebok-new"),

      "royal-glide-ripple": page("Royal Glide Ripple", "/sneakers/reebok/royal-glide-ripple", true),
      "sole-fury": page("Sole Fury", "/sneakers/reebok/sole-fury", true),
      white: page("White", "/sneakers/reebok/white", true),
    },
    converse: {
      ...page("Converse", "/sneakers/converse"),
      "all-star": page("All Star", "/sneakers/converse/all-star", true),
      "all-star-high": page("All Star High", "/sneakers/converse/all-star-high", true),
      "1970s": page("1970s", "/sneakers/converse/1970s", true),
    },
    timberland: {
      ...page("Timberland", "/sneakers/timberland"),
    },
    underarmour: {
      ...page("Under Armour", "/sneakers/underarmour"),
    },
    vans: {
      ...page("Vans", "/sneakers/vans"),
    },
    offWhite: {
      ...page("Off-White", "/sneakers/offwhite"),
    },
  },
}
